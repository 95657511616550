import React from 'react';
import { connect } from 'react-redux';
import CMSWrapper from '@ic-hosting/ich-contentful-cms-wrapper';
// import CMSWrapper, { ImageEditor, TypeEditor } from'../local-modules/@ic-hosting/ich-contentful-cms-wrapper/src';
import { bindActionCreators } from 'redux';
import 'jquery';

import { updateData } from '../data/contentful/actions';

import Layout from '../components/layout';

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = props.state;
    this.state.regions = []
  }

  componentWillMount() {

  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.updateData();
  }

  render() {
    
    const { content } = this.props;
    const { assets } = content.data;
    const interview = content.data.simpleCollections['1IkA9thoAyyZFyR6ROQ07a'];
    return (
      <Layout>
        <section className="bg-img-center inner-header divider parallax layer-overlay overlay-dark-5" data-bg-img={
          assets['2uXf8m4PcLiFnRGCGVuqzm'] ? assets['2uXf8m4PcLiFnRGCGVuqzm'].size({
            width: 1550
          })
        : null }
        >
          <div className="container pt-100 pb-50">
            <div className="section-content pt-100">
              <div className="row"> 
                <div className="col-md-12">
                  <h3 className="title text-white">INTERVIEW WITH <span className="text-theme-colored">WORLD MASTER CHONG CHUL RHE</span></h3>
                  <p className="text-white">8TH DAN THE FATHER OF AUSTRALIAN TAE KWON DO.</p>
                  <p className="text-white"><i>REPRINTED FROM MARCH 1985 ISSUE OF AUSTRALASIAN FIGHTING ARTS MAGAZINE</i></p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="cd-timeline2" className="cd-timeline-simple cd-container">
          <CMSWrapper {...interview}>
          { interview.elements.map((q,i) => {
            const className= i%2 ? "cd-timeline-content pr-0" : "cd-timeline-content text-right pr-0";
            return (
            <div key={q.id} className="cd-timeline-block">
              <CMSWrapper {...q}>
                <div className="cd-timeline-img cd-picture">
                  <img src="/js/vertical-timeline/img/cd-icon-location.svg" alt="Location" />
                </div>
                <div className={className}>
                  <h2 className="mt-0">{q.title}</h2>
                  <p dangerouslySetInnerHTML={{__html: q.body}}/> 
                  <span className="cd-date"/>
                </div>
              </CMSWrapper>
            </div>
          )}
          )}
          </CMSWrapper>
        </section>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({ state, content: state.contentful });

const mapDispatchToProps = dispatch => ({
  updateData: bindActionCreators(updateData, dispatch),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IndexPage);
